import XLSX from 'xlsx'

export function createFile(exportData, columnWidths) {
  var workBook = {
    SheetNames: ['Sheet1'],
    Sheets: {},
    Props: {}
  }

  var wopts = {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  }

  workBook.Sheets['Sheet1'] = XLSX.utils.json_to_sheet(exportData)
  workBook.Sheets['Sheet1']['!cols'] = columnWidths

  return new Blob([changeData(XLSX.write(workBook, wopts))], {
    type: 'application/octet-stream'
  })
}

export function download(file, fileName, document) {
  var tmpa = document.createElement("a")
  tmpa.download = fileName ? fileName + '.xlsx' : new Date().getTime() + '.xlsx'
  tmpa.href = URL.createObjectURL(file) //绑定a标签,
  tmpa.click() //模拟点击实现下载,

  setTimeout(function() { //延时释放,
    URL.revokeObjectURL(file) //用URL.revokeObjectURL()来释放这个object URL,
  }, 100)
}

function changeData(s) {
  //如果存在ArrayBuffer对象(es6) 最好采用该对象,
  if (typeof ArrayBuffer !== 'undefined') {
    //1、创建一个字节长度为s.length的内存区域,
    let buf = new ArrayBuffer(s.length)
    //2、创建一个指向buf的Unit8视图，开始于字节0，直到缓冲区的末尾,
    var view = new Uint8Array(buf)
    //3、返回指定位置的字符的Unicode编码,
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  } else {
    let buf = new Array(s.length)
    for (let i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
    return buf
  }
}
